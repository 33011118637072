var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('b-row', {
    attrs: {
      "clas": "align-items-center"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.audit ? _vm.audit.no : ''))])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.print($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak Audit ")], 1)], 1)], 1), _c('table', [_c('tr', [_c('td', [_vm._v("Tanggal")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.audit ? _vm.audit.tanggal : ''))])]), _c('tr', [_c('td', [_vm._v("Status")]), _c('td', [_vm._v(":")]), _c('th', [_vm.audit.status == 1 ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v("Selesai")]) : _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("Belum Selesai")]), _vm.audit.status == 0 ? _c('strong', {
    staticClass: "ml-1 cursor-pointer text-success",
    on: {
      "click": _vm.confirmSelesai
    }
  }, [_vm._v("Tandai Selesai?")]) : _vm._e()], 1)]), _c('tr', [_c('td', [_vm._v("Keterangan")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.audit ? _vm.audit.keterangan : ''))])])])], 1), _c('h4', {
    staticClass: "mb-1"
  }, [_vm._v("Rincian Aset "), _vm.rincians.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.rincians.length) + ")")]) : _vm._e()]), _c('b-card', {
    staticClass: "mb-2"
  }, [_vm.audit.status == 0 ? _c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openAssetModal($event);
      }
    }
  }, [_vm._v("Pilih Aset")]) : _vm._e(), _c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.rincians
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.penyimpanan && item.penyimpanan.asset ? _c('strong', [_vm._v(" " + _vm._s(item.penyimpanan.asset.nama))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Asset tidak ditemukan")])];
      }
    }, {
      key: "cell(blok)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.penyimpanan && item.penyimpanan.blok ? _c('strong', [_vm._v(" " + _vm._s(item.penyimpanan.blok.blok))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Lokasi Aset tidak ditemukan")])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah,
            callback: function callback($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        })];
      }
    }, {
      key: "cell(selisih_barang)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(parseInt(item.jumlah) - parseInt(item.stok)) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref6) {
        var item = _ref6.item,
          index = _ref6.index;
        return [_vm.audit && _vm.audit.status == 0 ? _c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "XCircleIcon",
            "size": "24"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item, index);
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _c('br'), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.rincians.length < 1 || _vm.audit.status == 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.audit.status == 0 ? 'Simpan' : 'Audit sudah diselesaikan') + " ")])], 1)], 1), _c('penyimpanan-modal', {
    on: {
      "submit": _vm.onChooseStock
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }